import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Minoxidil",
  "path": "products/minoxidil",
  "date": "2021-07-09"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A hair growth stimulant commonly known by its brand name Rogaine.
It's available as a foam or liquid and is one of the few proven treatments for hair loss,
though it does not address the root cause of hair loss, so ultimately it is something used to improve the quality of your hair, ideally in conjunction with something that suppresses DHT.`}</p>
    <h2>{`My Experience`}</h2>
    <p>{`I have been using minoxidil on and off since around the age of twenty. I stopped a few times when I was younger because I used the liquid version, which created a film on my head that would peel off. It made me feel gross and I heard it made you lose your hair.
The truth is, there is a hair shedding effect when starting and stopping it, like all hair loss drugs that `}<em parentName="p">{`actually`}</em>{` work. Ceasing minoxidil just brings you to where you would be in your hair loss journey, which can really sneak up on you as you don't
always lose your hair consistently throughout life.`}</p>
    <p>{`My girlfriend at the time made me feel weird about using it (biggest mistake ever--do NOT let family, friends, or lovers persuade you as they will `}<strong parentName="p">{`never`}</strong>{` understand the hair loss journey).
I may have tried it once or twice again, but I didn't start up again till when I created this site. I gave the foam version a shot, and 6-packs from Amazon are significantly cheaper than the grocery store or drug store.
The foam version applies easy, dries fast, and doesn't leave a gross film.`}</p>
    <p>{`If I use too much, it can make some hairs stick, look greasy, and look like bad product, but this can be remedied with some water and a more careful application to your scalp.
I heard that the active ingredient (minoxidil) can get stuck in the foam, and thus your hair, not letting it get to the source, so for your longer hair styles, it can be a little difficult.
YouTuber, Kevin Mann, has a guide on how to turn it into a liquid, which I'd like to try myself.`}</p>
    <p>{`I currently only use half the recommended dose, as recommended by `}<a href="https://www.youtube.com/watch?v=jztEaZKVz1Y" target="_blank">{`The Hair Loss Show`}</a>{`.`}</p>
    <h2>{`Where I Buy`}</h2>
    <p>{`The `}<a href="https://amzn.to/3xA6uKI" target="_blank">{`6 month supply of 5% Minoxidil foam`}</a>{` below is the exact one I use. It is a little cheaper than $12 per container. The 6 month supply of the `}<a href="https://amzn.to/3hZ95Hy" target="_blank">{`liquid version`}</a>{` is half that price, so if you are really on a budget, that is an option, but I do not recommend it.
I do receive commission when you purchase using one of the links, but you are more than welcome to buy minoxidil elsewhere, as it sold in drug stores, but it will cost more. The brand name version will cost even more, so always use a generic, such as the Kirkland/Costco brand.
The results seem to be universally the same, so you aren't necessarily buying an inferior product.`}</p>
    <iframe style={{
      "width": "120px",
      "height": "240px"
    }} marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=lytsmh-20&marketplace=amazon&amp;region=US&placement=B01GCVMLA2&asins=B01GCVMLA2&linkId=9ae7342829eccd10f2791c7083a17a2b&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      